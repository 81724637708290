// SidePanel.scss

@use "../../../assets/styles/variables" as *;

$side-panel-width: 325px;
$background-color: #f8f9fa;
$overlay-color: rgba(0, 0, 0, 0.5);

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-color;
  z-index: 100;
}

.side-panel {
  position: fixed;
  right: -100%;
  top: 0;
  height: 100%;
  width: $side-panel-width;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 101;

  padding: $padding-xs $padding-sm;

  font-family: $font-family;

  @media screen and (min-width: 375px) {
    width: 350px;
  }

  @media screen and (min-width: 768px) {
    width: 500px;
  }

  &.open {
    right: 0;
  }

  .side-panel-header {
    color: black;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: $padding-xs;
    margin-bottom: $margin-xs;

    border-bottom: 1px solid #ddd;

    &__back {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: inherit;

      background: none;
      border: none;
      outline: none;

      span {
        font-weight: 800;
        font-family: $font-family;
      }

      svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;

        fill: none;
        stroke: black;
        stroke-width: 3px;

        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }

  .side-panel__content {
    overflow-y: auto;
    height: calc(100% - 3rem);
    position: relative;
  }

  .side-panel-actions {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: $background-color;
    border-top: 1px solid #ddd;

    button {
      color: #fff;
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-radius: 4px;

      //   &:hover {
      //     background-color: darken($primary-color, 10%);
      //   }

      //   &.close-panel {
      //     background-color: $secondary-color;

      //     &:hover {
      //       background-color: darken($secondary-color, 10%);
      //     }
      //   }
      // }
    }
  }
}