@use "../../../assets/styles/variables" as *;

.footer {
  font-size: $font-size;
  font-family: $font-family-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;

  &.loading {
    display: block;
    width: 600px;
    height: 12.5px;
    border-radius: 10px;
  }

  div {
    gap: 0.35rem;
    margin-left: 0.5rem;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    color: rgb(59, 59, 59);
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.5;
    }
  }
}