@use "./variables" as *;
@use "./scrollbar" as *;

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background: $bg-color;
  font-family: $font-family;
  scroll-behavior: smooth;
}

html {
  --cursor-color: #333;
}

section {
  //overflow-x: hidden;
  width: 100%;
  position: relative;
}

/* Element Styles */
ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.custom-cursor {
  background-color: var(--global-picker-color, #9bdd75);
}

/* Flexbox Utilities */
.flex {
  display: flex;
}

.flex-center {
  @extend .flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-between {
  @extend .flex;
  justify-content: space-between;
  align-items: center;
}

/* Grid Utilities */
.grid {
  display: grid;
}

/* Wrapper */
.wrapper {
  margin: 0 auto;
  position: relative;

  height: 100vh;
}

.wrapper-header {
  height: $header-height;
  padding: 0 $padding-md;
  width: 100%;
}

.wrapper-main {
  min-height: calc(100dvh - $footer-height - $header-height);
  padding: 0 $padding-md;

  width: 100%;

  position: relative;

  overflow-y: scroll;

  @media screen and (max-width: $breakpoint-md) {
    height: calc(100dvh - $footer-height - $header-height);
  }
}

.wrapper-footer {
  height: $footer-height;
  padding: 0 $padding-md;
  width: 100%;
}

/* Header */
header {
  @extend .flex-between;
}

/* Loading Animation */
.loading {
  background: linear-gradient(100deg, #ddd9c8 30%, #f0ebd7 50%, #ddd9c8 70%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}