@use "../../../assets/styles/variables" as *;

.contact-text-area {
  position: relative;
  max-width: 100%;
  margin: $margin-sm 0;

  .is-invalid {
    border-color: #ff3860; // Example error color
  }

  .error-message {
    color: #ff3860; // Example error color
    font-size: 0.75rem; // Small font size for error message
    position: absolute;
    bottom: -20px; // Position the error message below the input
    left: 0;
  }

  .contact-textarea {
    resize: vertical;
    font-size: 16px;
    padding: 10px 50px 0px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    background: transparent;

    font-family: $font-family;

    &:focus {
      outline: none;
    }

    &:focus~label,
    &:valid~label {
      top: -20px;
      font-size: 14px;
      color: #000000;
    }

    &:focus~.bar:before,
    &:focus~.bar:after {
      width: 50%;
    }

    &:focus~.highlight {
      animation: inputHighlighter 0.3s ease;
    }
  }

  label {
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 15px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;

    &::after {
      content: "*";
      color: rgb(235, 99, 99);
    }
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;

    &::before,
    &::after {
      content: "";
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: #000000;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    &::before {
      left: 50%;
    }

    &::after {
      right: 50%;
    }
  }

  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }
}

@keyframes inputHighlighter {
  from {
    background: #000000;
  }

  to {
    width: 0;
    background: transparent;
  }
}