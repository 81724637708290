.toast {
  display: flex;
  align-items: center;

  background-color: #252525; /* Darker background for better contrast */
  color: #fff;

  padding: 15px 20px;
  border-radius: 10px; /* More rounded corners */

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Slightly larger shadow */
  font-size: 13px; /* Slightly larger font */

  position: fixed;
  bottom: 20px;
  right: 20px; /* Start off-screen to the right */
  z-index: 1000;

  transform: translateX(1000px);
  transition: transform 0.3s ease; /* Animation */

  span {
    max-width: 280px;
    text-align: center;
  }

  .close-button {
    margin-left: 25px;
    cursor: pointer;
    font-size: 16px;
    color: #ccc;
    background: none;
    border: none;
    transition: color 0.3s ease; /* Transition on hover */
  }

  &.show {
    transform: translateX(0);
    right: 20px; /* Slide in to visible position */
  }
}
