@use "../../assets/styles/variables" as *;

// Loader.scss
.about__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 150px);
  gap: $margin-lg; // Make sure to define $margin-lg in your SCSS variables

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $margin-xs; // Define $margin-xs as needed

    width: 100%;

    span {
      display: block;
      width: 600px;
      height: 12.5px;
      border-radius: 10px;

      &:first-of-type {
        margin-bottom: $margin-xxs; // Use defined margins
        width: 300px;
        height: 20px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }

      &:last-of-type {
        width: 400px;
        height: 500px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $margin-xxs; // Use defined $margin-xxs

    width: 100%;

    span {
      display: block;
      width: 400px;
      height: 10px;
      border-radius: 25px;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      &:first-of-type {
        width: 300px;
        height: 10px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }

      &:last-of-type {
        width: 500px;
        height: 10px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}

.about-container {
  color: #333;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;

  .highlight {
    position: relative;
    padding: $padding-xxxxs $padding-xxs;
    display: inline-block;

    cursor: pointer;
    user-select: none;

    &::before {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3.5px;
      background-color: transparent;
      /* Default background */

      border-radius: 10px;

      transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      z-index: -1;
    }

    &:hover {
      &::before {
        height: 100%;
      }
    }
  }

  .highlight.yellow::before {
    background-color: var(--global-picker-color, #9bdd75);
  }

  .highlight.green::before {
    background-color: rgb(183, 214, 183);
    /* Green highlight */
  }

  .highlight.blue::before {
    background-color: lightblue;
    /* Blue highlight */
  }

  h1,
  h2,
  h3 {
    color: #2c3e50;
    margin-bottom: $margin-sm;
  }

  .intro-section {
    margin-bottom: 40px;

    .intro-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        max-width: 800px;
        line-height: 1.6;
      }

      a {
        margin-top: $margin-md;
        color: black;
        border: black 1px solid;
        border-radius: 15px;
        padding: $padding-xxxs $padding-xs;
        margin-top: $margin-md;
        overflow: hidden;
        height: 33px;

        width: fit-content;
        display: block;

        &:hover {
          span {
            transform: translateY(-100%);
          }
        }

        span {
          transition: all 0.3s ease-in-out;
          display: block;

          line-height: 1.65;

          user-select: none;
        }
      }
    }
  }

  .passions-section,
  .skills-section,
  .languages-section,
  .experience-section {
    margin-bottom: 40px;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin: 10px 0;
        line-height: 1.6;

        strong {
          font-weight: bold;
        }
      }
    }
  }

  .skills-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $margin-sm;

    .skill-category {
      //width: 45%;
      margin: 0 0 20px;

      max-width: calc(600px - $margin-sm);

      &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 20px;

        &__tag {
          background-color: var(--global-picker-color, #9bdd75);
          color: #000000;

          padding: 4px 10px;

          border-radius: 20px;
          font-size: 11px;
          font-weight: 500;
          transition: background-color 0.3s ease;
        }
      }

      h3 {
        border-bottom: 2px solid #2c3e50;
        padding-bottom: 5px;
      }

      ul {
        list-style: none;
        padding: 0;
      }

      li {
        // margin: 10px 0;
        text-align: left;
      }
    }
  }

  .languages-section {
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      z-index: 2;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: $margin-xxs;

      li {
        border: 1px solid #2c3e50;
        border-radius: 50%;

        user-select: none;

        overflow: hidden;

        position: relative;

        height: 100px;
        width: 100px;

        &:hover {
          img {
            opacity: 0.7;
          }
        }

        img {
          height: 100%;
          width: 100%;

          z-index: 1;

          object-fit: cover;

          opacity: 0;

          transition: opacity 0.3s ease-in-out;
        }

        span {
          line-height: 0.5;
          font-size: $font-size-s;
          margin-bottom: $margin-xxxxs;
        }
      }
    }
  }

  .timeline {
    position: relative;
    padding-top: 20px;
    margin-top: 20px;
    text-align: left;

    .timeline-item {
      position: relative;
      margin-bottom: 30px;
      padding-left: 40px;

      &:before {
        content: "";
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: #2c3e50;
      }

      .timeline-dot {
        position: absolute;
        left: 11px;
        top: 0;
        width: 10px;
        height: 10px;
        background: #2c3e50;
        border-radius: 50%;
      }

      .timeline-content {
        h3 {
          margin: 0 0 5px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          object-fit: cover;
        }

        span {
          display: flex;
          margin-bottom: 10px;
          font-size: 0.9em;
          color: #7f8c8d;
        }

        &__link {
          color: #7f8c8d !important;
          text-decoration: none !important;
          font-weight: bold;
          margin-left: 5px;

          @media screen and (max-width: 400px) {
            display: block;
            margin-top: 5px;
          }
        }

        a {
          font-weight: bold;
          color: #2c3e50;
          text-decoration: underline;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
