@use "../../../assets/styles/variables" as *;

.project {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;

  // Default container sizing for larger screens
  max-width: 600px;
  width: 100%;
  position: relative;

  // Mobile design adjustments
  @media screen and (max-width: 600px) {
    max-width: 100%; // Allow the card to take up full width on smaller screens
    margin: 0 10px; // Add some side margin to avoid touching edges
    max-height: 335px; // Increase card height for better readability
  }

  @media screen and (max-width: 400px) {
    height: 300px;
  }



  &::before {
    content: "";
    pointer-events: none;
    display: block;
    position: absolute;
    inset: 0px;
    z-index: -1;
    background: radial-gradient(circle,
        white 10%,
        whitesmoke 11%,
        whitesmoke 100%);
    transition: all 0.3s ease;
  }

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: 1;
    inset: 0px;
    opacity: 1;
    background: linear-gradient(rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.78) 80%);
    transition: all 0.3s ease;
  }

  @media screen and (min-width: 600px) {
    &::after {
      opacity: 0.3;
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      .project__details {
        opacity: 1;
      }
    }
  }

  // Image/Video container
  &__image {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 250px;
    max-height: 335px; // Default sizing for larger screens
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 400px) {
      height: 100%;
    }

    img,
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: transform 0.3s ease;
      vertical-align: middle;
      border-style: none;

      @media screen and (max-width: 400px) {
        height: 100%;
      }


      &:hover {
        transform: scale(1.05);
      }


    }

    // Ensures image covers the entire square on smaller screens
    @media screen and (max-width: 600px) {
      img {
        object-fit: cover; // Ensures the image covers the square
        width: 100%;
        height: 100%; // Ensures the height covers the container fully
      }
    }
  }

  // Text container
  &__details {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 50;
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 600px) {
      opacity: 0;
    }

    // Make text more readable on mobile
    @media screen and (max-width: 600px) {
      padding: 15px; // Adjust padding to make more room for text
    }

    @media screen and (max-width: 500px) {
      padding: 10px; // Adjust padding to make more room for text

    }

    &__title {
      font-size: 1.75rem; // Default font size
      color: white;
      font-weight: bold;

      // Increase font size for mobile readability
      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 500px) {
        font-size: 1.25rem;
      }
    }

    &__description {
      font-size: $font-size-sm; // Use a smaller font size for larger screens
      margin: 0 0 20px;
      color: rgb(221, 219, 219);
      line-height: 1.2; // Slightly increase line height for better readability

      // Increase font size and line height on mobile
      @media screen and (max-width: 600px) {
        font-size: 1rem; // Slightly increase the font size on mobile
        line-height: 1.4; // Increase line height to avoid crowding
      }

      @media screen and (max-width: 500px) {
        font-size: 0.9rem; // Slightly increase the font size on mobile
        line-height: 1.3; // Increase line height to avoid crowding
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 20px;

      .project__tag {
        background-color: var(--global-picker-color, #9bdd75);
        color: #000000;
        padding: 4px 10px;
        border-radius: 20px;
        font-size: 11px;
        font-weight: 500;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;

        // Make tags larger and easier to read on mobile
        @media screen and (max-width: 600px) {
          font-size: 13px;
          padding: 6px 12px;
        }

        @media screen and (max-width: 500px) {
          font-size: 11px;
          padding: 4px 10px;

        }
      }
    }
  }
}