@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap");

// =========== Background Colors ===========

$bg-color: #f9f4e1;

$header-height: 80px;
$footer-height: 70px;

// =========== Text Colors ===========

// =========== Font Sizes ===========
$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-sm: 14px;
$font-size: 15px;
$font-size-m: 18px;

$font-size-l: 24px;
$font-size-xl: 30px;
$font-size-xxl: 36px;
$font-size-xxxl: 42px;
$font-size-xxxxl: 48px;

// ============ Sizes ============
$header-height: 80px;
$footer-height: 70px;

// =========== Scrollbar ===========

$scroll-thumb-color: hsl(180, 1%, 57%);
$scroll-thumb-hover-color: hsl(0, 0%, 33%);

// =========== Font Families ===========
$font-family: "Poppins", sans-serif;
$font-family-secondary: "Syne", sans-serif;

// =========== Font Weights ===========
$font-weight-light: 300;
$font-weight: 400;
$font-weight-bold: 700;

// =========== Font Styles ===========
$font-style: normal;

// =========== Line Heights ===========
$line-height: 1.5;

// =========== Letter Spacings ===========
$letter-spacing: 0;

// =========== Border Radius ===========
$border-radius: 12px;

// =========== Transitions ===========
$transition: all 0.3s ease-in-out;

// =========== Breakpoints ===========
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 769px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// =========== Margin ===========
$margin-xxxxs: 0.125rem;
$margin-xxxs: 0.25rem;
$margin-xxs: 0.5rem;
$margin-xs: 1rem;
$margin-sm: 1.5rem;
$margin-md: 2rem;
$margin-lg: 2.5rem;
$margin-xl: 3rem;
$margin-xxl: 3.5rem;
$margin-xxxl: 4rem;
$margin-xxxxl: 4.5rem;

// =========== Padding ===========
$padding-xxxxs: 0.125rem;
$padding-xxxs: 0.25rem;
$padding-xxs: 0.5rem;
$padding-xs: 1rem;
$padding-sm: 1.5rem;
$padding-md: 2rem;
$padding-lg: 2.5rem;
$padding-xl: 3rem;
$padding-xxl: 3.5rem;
$padding-xxxl: 4rem;
$padding-xxxxl: 4.5rem;
