@use "../../../assets/styles/variables" as *;
@use "sass:color";

.navbar-loader {
  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  span {
    display: none;

    &:last-of-type {
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 10px;
    }

    @media screen and (min-width: $breakpoint-md) {
      display: block;
      width: 100px;
      height: 10px;
      border-radius: 10px;

      &:last-of-type {
        width: 100px;
        height: 10px;
      }
    }
  }
}

.navigation-menu {
  width: 100%;
  height: 100%;

  position: relative;
  align-items: center;

  transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 98;

  &.navbar-default {
    color: black;

    .navigation-menu__toggle {
      &.true {
        span {
          background: black;
        }
      }

      span {
        background: black;
      }

      span {
        background: black;
      }
    }

    .navigation-menu__list {
      li {
        a {
          color: black;

          &::before {
            border-bottom: 2px solid black;
          }
        }
      }
    }
  }

  &__toggle {
    flex-direction: column;

    width: 30px;
    height: 20px;

    z-index: 1000;
    user-select: none;
    cursor: pointer;

    &.false {
      @media screen and (min-width: $breakpoint-md) {
        display: none;
      }
    }

    &.true {
      span {
        &:first-child {
          transform: rotate(45deg) translate(-8px, -8px);
          background: #000000;
        }

        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, 0px);
          background: #000000;
        }

        &:last-child {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
          background: #000000;
        }
      }
    }

    span {
      width: 100%;
      height: 2px;
      background: black;

      border-radius: 5px;
      z-index: 1;

      transform-origin: 5px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }

      &:last-child {
        transform-origin: 0% 100%;
      }
    }
  }

  &__list {
    background: color.adjust($bg-color, $lightness: 2%);
    width: 200px;
    height: 100vh;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    position: absolute;
    left: -35px;
    top: 0;

    gap: 10px;
    overflow: hidden;

    padding: $padding-xxxxl $padding-md;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      height 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      padding 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    .color-picker {
      height: 33px;
      width: 50px;
      border: black 1px solid;
      border-radius: 15px;

      background-color: transparent;

      position: relative;

      @media screen and (max-width: 875px) {
        width: 75%;
        margin-top: 30px;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      &::before {
        content: none;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        top: 7px;
        left: 7px;
        height: 100%;

        background-color: var(--global-picker-color, #9bdd75);
        z-index: -1;
        transition: all 0.35s;
        border-radius: inherit;
      }

      &:hover {
        &:after {
          top: 0px;
          left: 0px;
        }
      }
    }

    &.true {
      transform: translate(0, 0);
    }

    &.false {
      transform: translate(-100%, 0);

      @media screen and (min-width: 875px) {
        transform: translate(0, 0);
        height: 100%;
        opacity: 1;

        flex-direction: row;
        align-items: center;
        background: transparent;
        position: relative;
        overflow: visible;
        box-shadow: none;
        padding: $padding-md 0;
        gap: 20px;
        width: auto;
      }
    }

    li {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      &:last-of-type {
        a {
          color: black;
          border: black 1px solid;
          border-radius: 15px;
          padding: $padding-xxxs $padding-xs;
          overflow: hidden;
          height: 33px;

          width: fit-content;

          @media screen and (max-width: 875px) {
            margin-top: 50px;
          }

          &::before {
            display: none;
          }

          &:hover {
            span {
              transform: translateY(-100%);
            }
          }

          span {
            transition: all 0.3s ease-in-out;
            display: block;

            line-height: 1.6;

            user-select: none;
          }
        }
      }

      a {
        color: #000;
        transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;

          border-bottom: 2px solid #000;

          width: 0;
          height: 1px;

          transition: width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }

        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      height: 100%;
      opacity: 1;
    }
  }
}