@use "_variables" as *;

/* ===============Scrollbar===============*/
/*width*/
::-webkit-scrollbar {
    width: 7.5px;
}

/* Track */
::-webkit-scrollbar-track {
    margin-top: 11vh;
    margin-bottom: 5vh;
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scroll-thumb-color;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $scroll-thumb-hover-color;
}

::-webkit-scrollbar-button:end:increment {
    height: 5%;
    display: block;
    background: transparent;
}