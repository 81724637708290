@use "../../assets/styles/variables" as *;

.contact-form-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 20px;
  padding: 50px 0;
  border-radius: 12px;
  max-width: 90vw; // Flexibele breedte voor responsiviteit
  margin: 0 auto;

  // Voor schermen groter dan 1800px
  @media screen and (min-width: 1800px) {
    max-width: 1600px;
  }

  // Voor schermen groter dan 2400px (bijv. 4K)
  @media screen and (min-width: 2400px) {
    max-width: 2200px;
  }

  // Eventueel voor nog grotere schermen (bijv. 8K)
  @media screen and (min-width: 3400px) {
    max-width: 3000px;
  }

  .contact-form-section {
    h2 {
      font-size: $font-size-xxl;
      font-family: $font-family-secondary;
      text-align: center;
      color: #333;
    }

    p {
      font-size: 16px;
      margin-bottom: $margin-md;
      color: #4d565e;
      line-height: 1.7;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;

      .form-group {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
          flex-direction: row;
          gap: $margin-md;
        }
      }
    }

    .services {
      &__header {
        margin-bottom: $margin-xs;
        display: block;
        position: relative;

        &::after {
          content: "*";
          position: absolute;
          top: 0;
          color: red;
        }
      }

      .service-options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        transition: all 0.3s ease-in-out;

        // Enable internal scrolling only on larger screens
        @media screen and (min-width: 768px) {
          height: 170px;
          //max-height: 100vh;
          overflow-y: auto;
        }

        &.expanded {
          height: 600px;

          @media screen and (min-width: $breakpoint-xxl) {
            height: 500px;
          }
        }

        @media screen and (min-width: 768px) {
          flex-flow: row wrap;
          gap: 10px;
        }

        .service {
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 15px;
          padding: 15px;
          display: flex;
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          @media screen and (min-width: 768px) {
            width: calc(50% - 10px);
          }

          &:hover {
            background-color: var(--global-picker-color);
          }

          input[type="checkbox"] {
            display: none;
          }

          &__name {
            font-size: 16px;
            font-weight: bold;
            display: block;
          }

          &__description {
            font-size: 14px;
            margin-bottom: 5px;
            line-height: 1.8;
          }

          &.checked {
            background-color: var(--global-picker-color);
          }
        }

        .buttons {
          display: flex;
          justify-content: flex-start;
          gap: $margin-xxs;

          button {
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 15px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            background: transparent;
            width: fit-content;

            svg {
              color: black;
              fill: none;
              width: 15px;
              height: 15px;

              path {
                stroke: black;
                stroke-width: 2px;
                stroke-linecap: round;
                stroke-linejoin: round;
              }
            }

            &:hover {
              background-color: var(--global-picker-color);
              border-color: var(--global-picker-color);
            }

            &:last-of-type:hover {
              background-color: #e73e3e;
              border-color: #e73e3e;

              svg {
                color: #fcfcfc;

                path {
                  stroke: #fcfcfc;
                }
              }
            }
          }
        }
      }
    }
  }

  .error {
    color: #ff3860;
    font-size: 0.75rem;
    margin-top: $margin-xs;

    &::before {
      content: "*";
      margin-right: 5px;
    }
  }
}