@use "../../../assets/styles/variables" as *;

// ProjectDetailSidePanel.scss
$side-panel-width: 350px;
$primary-color: #007bff;
$secondary-color: #6c757d;
$background-color: #f8f9fa;
$overlay-color: rgba(0, 0, 0, 0.5);
$font-color: #212529;

.project-detail-panel {
  &__header {
    h3 {
      line-height: 1;
    }

    p {
      font-size: $font-size-s !important;
      font-weight: 400 !important;
      color: #6c757d !important;
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: $font-size-sm;
    line-height: 1.6;
    color: $font-color;

    strong {
      color: rgb(48, 64, 100);
    }
  }

  video {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 10px;
  }

  img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 10px;
  }

  &__project {
    &__links {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      margin-left: $margin-xs;

      a {
        display: inline-block;
        margin-right: 10px;

        color: #6c757d;

        width: 15px;
        height: 15px;

        svg {
          width: 100%;
          height: 100%;
          stroke: #6c757d;
          transition: color 0.3s ease;
        }
      }
    }
  }

  &__project-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: $margin-xs;

    &__tag {
      background-color: var(--global-picker-color, #9bdd75);
      color: #000000;

      padding: 4px 10px;

      border-radius: 20px;
      font-size: 11px;
      font-weight: 500;
      transition: background-color 0.3s ease;
    }
  }
}