@use "../../assets/styles/variables" as *;

.logo {
  p {
    font-family: $font-family-secondary;
    font-size: 18px;
    font-weight: bold;
    font-optical-sizing: auto;
    color: black;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 15px;
    }
  }

  span {
    display: block;
    font-family: $font-family;
    color: #999999;
    font-size: 11px;
    line-height: 100%;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 10px;
    }
  }
}

.logo-loader {
  gap: 5px;

  p {
    width: 150px;
    height: 10px;
    border-radius: 10px;
  }

  span {
    display: block;
    width: 100px;
    height: 10px;
    border-radius: 10px;
  }
}