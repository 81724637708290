@use "../../../../assets/styles/variables" as *;

$border-radius: 12px; // Rounded corners for the switches

.switches-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: $margin-xxs;

  width: 100%;

  position: relative;

  .switch {
    height: 33px;
    width: fit-content;

    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 15px;

    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background: transparent;

    position: relative;

    &.active {
      background-color: var(--global-picker-color);
      border-color: var(--global-picker-color);
    }

    &:hover {
      border-color: var(--global-picker-color);
      background-color: var(--global-picker-color);
    }
  }
}

// Additional responsive styling
@media (max-width: 600px) {
  .switches-container {
    flex-direction: column;
    align-items: center;

    .switch {
      width: 100%;
      text-align: center;
    }
  }
}