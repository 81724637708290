// FilterSidePanel.scss

@use "../../../assets/styles/variables" as *;

.filter-panel {
  width: 100%;
  height: 100%;

  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h3 {
      margin: 0;
      font-size: 1.5rem;
    }

    .clear-filters {
      display: flex;
      align-items: flex-end;
      gap: 8px;

      cursor: pointer;
      background: none;
      border: none;

      color: #ff6666;
      font-weight: bold;
      font-size: 14px;

      transition: color 0.3s ease;

      span {
        margin-bottom: -2px;
      }

      &:hover,
      &:active {
        color: #ff4444;

        svg {
          animation: swipe 0.5s ease-in-out forwards;
        }
      }
    }

    @keyframes swipe {
      0% {
        transform: translateX(0) rotate(0deg);
      }

      50% {
        transform: translateX(-10px) rotate(-10deg);
      }

      100% {
        transform: translateX(0) rotate(0deg);
      }
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;

      fill: none;
      stroke: #ff6666;
      stroke-width: 2px;
      stroke-linecap: round;

      transition: transform 0.3s ease;
      transform-origin: bottom left;
    }
  }

  &__content {
    height: 100%;
  }

  .filter-group {
    margin-bottom: 1.5rem;
    width: 100%;
    position: relative;

    h4 {
      font-size: 1.125rem;
      color: black;
      margin-bottom: 0.5rem;
    }
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chip {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  input {
    display: none;
  }

  &.selected {
    background-color: var(--global-picker-color, #9bdd75);
  }

  &:hover {
    background-color: var(--global-picker-color, #9bdd75);
  }

  &.selected:hover {
    background-color: var(--global-picker-color, #9bdd75);
  }
}

.radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-label {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;

  span {
    margin-bottom: -2px;
  }

  input {
    margin-right: 10px;
    cursor: pointer;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid var(--global-picker-color, #9bdd75);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    outline: none;
    transition: border-color 0.3s;

    &:checked::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: var(--global-picker-color, #9bdd75);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s;
    }
  }

  &.selected {
    border-color: var(--global-picker-color, #9bdd75);
    background-color: #f0f0f0;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &.selected:hover {
    background-color: #f0f0f0;
  }
}