@use "../../../assets/styles/variables" as *;

.scroll-to-top {
  position: fixed;
  bottom: $footer-height + 5px;
  right: calc((100vw - 1200px) / 2 + 20px);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  @media screen and (max-width: 1200px) {
    right: 20px;
  }

  svg {
    fill: none;

    width: 20px;
    height: 20px;

    path {
      stroke: black;
      stroke-width: 3px;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}