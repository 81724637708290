@use "../../assets/styles/variables" as *;

.work {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  justify-items: center;
  gap: $margin-lg;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 150px);
    gap: $margin-lg;

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: $margin-xs;

      width: 100%;

      span {
        display: block;
        width: 600px;
        height: 12.5px;
        border-radius: 10px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }

        &:first-of-type {
          margin-bottom: $margin-xs;
          width: 400px;
          height: 20px;

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }

    &__switch {
      display: flex;
      justify-content: center;
      align-items: center;

      gap: $margin-xxs;

      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
      }

      span {
        display: block;
        width: 105px;
        height: 35px;
        border-radius: 25px;
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $margin-xs;

      max-width: 100%;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: $margin-xs;
      }

      span {
        display: block;
        width: 600px;
        height: 300px;
        border-radius: 10px;

        @media screen and (max-width: 600px) {
          max-width: 100%;
        }
      }
    }
  }

  &__header {
    max-width: 1200px;
    text-align: center;
    margin-top: $margin-lg;

    h2 {
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: regular;
    }

    p {
      font-size: 16px;
      font-weight: regular;
      font-family: $font-family;

      margin-top: 20px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $margin-xs;

    &__empty {
      text-align: center;
    }
  }

  &__switch {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: $margin-xxs;

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;

      gap: 0;
    }

    &__filter {
      border: 1px solid #ccc;
      border-radius: 15px;
      padding: 15px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      background: transparent;

      @media screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
      }

      svg {
        color: black;
        fill: none;
        width: 15px;
        height: 15px;

        path {
          stroke: black;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }

      &:hover {
        background-color: var(--global-picker-color);
      }
    }
  }
}