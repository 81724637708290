@use "../../../assets/styles/variables" as *;

.slider-container {
  position: relative;
  touch-action: pan-y;
  height: 300px;
  width: 100%;
  margin-bottom: $margin-sm;

  svg {
    width: 30px;
    height: 30px;
    fill: none;

    path {
      stroke: white;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
    }
  }

  @media screen and (min-width: 768px) {
    height: 600px;
  }

  .info-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    border-radius: 5px;
    font-size: 1.2em;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .finger {
      fill: #fff;
      animation: swipe 3s infinite cubic-bezier(0.4, 0, 0.6, 1);
    }
  }

  .slider-wrapper {
    position: relative;
    height: 100%; // Zorg dat de wrapper de volledige hoogte inneemt
    user-select: none;
    transition: transform 0.5s ease-in-out;

    &.blurred {
      filter: blur(5px);
    }
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // Zorg dat de slides de volledige hoogte van de container innemen
    display: flex;
    justify-content: center;
    align-items: center; // Centreer de afbeelding zowel horizontaal als verticaal

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; // Zorg dat de afbeelding netjes binnen de slide past
      display: block;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
}

@keyframes swipe {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}