@use "../../assets/styles/variables" as *;

.hero__content {
  position: relative;
  text-align: center;
  align-items: center;
  padding: 50px 0;

  gap: 150px;

  width: 100%;

  &__header {
    position: relative;
    text-align: center;
    align-items: center;

    width: 100%;

    margin-top: $margin-xxl;
  }

  .video-container {
    position: relative;

    .icon-container {
      position: absolute;
      top: 5px;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      width: 30px;
      height: 30px;

      background-color: rgba(225, 225, 255, 0.5);
      border-radius: 50%;

      @media (max-width: 767px) {
        top: 10px;
        right: 10px;

        height: 25px;
        width: 25px;

        .fullscreen-icon {
          font-size: 0.8rem;
        }
      }
    }
  }

  video {
    cursor: pointer;
    width: 100%;
    height: auto;
    border-radius: 15px;
    transform-origin: center center;
    transition: transform 0.5s ease;

    /* For mobile devices */
    @media (max-width: 767px) {
      width: 100%;
      height: auto;
    }

    &.fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1000;
      object-fit: cover;

      /* For mobile devices */
      @media (max-width: 767px) {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
        /* Ensure the video fits the screen */
      }
    }
  }

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    text-align: center;

    font-size: $font-size-m;
    font-family: $font-family-secondary;

    @media screen and (min-width: 360px) {
      font-size: $font-size-l;
    }

    @media screen and (min-width: 540px) {
      font-size: $font-size-xl;
    }

    @media screen and (min-width: 768px) {
      font-size: $font-size-xxl;
    }

    @media screen and (min-width: 992px) {
      font-size: $font-size-xxxl;
    }

    @media screen and (min-width: 1200px) {
      font-size: $font-size-xxxxl;
    }

    @media screen and (min-width: 1424px) {
      gap: 16px;
    }
  }

  p {
    font-size: $font-size-s;
    margin-top: $margin-xxxxs;

    color: #333;

    @media screen and (min-width: 360px) {
      font-size: $font-size;
    }
  }

  .cta-button {
    margin-top: $margin-md;
    color: black;
    border: black 1px solid;
    border-radius: 15px;
    padding: $padding-xxxs $padding-xs;
    overflow: hidden;
    height: 33px;

    width: fit-content;

    &:hover {
      span {
        transform: translateY(-100%);
      }
    }

    span {
      transition: all 0.3s ease-in-out;
      display: block;

      line-height: 1.6;

      user-select: none;
    }
  }
}

.hero-rotation {
  overflow: hidden;
  height: calc($font-size-xxxxl * 1.2);

  @media (max-width: 1200px) {
    height: calc($font-size-xxxl * 1.2);
  }

  @media (max-width: 992px) {
    height: calc($font-size-xxl * 1.2);
  }

  @media (max-width: 768px) {
    height: calc($font-size-xl * 1.2);
  }

  @media (max-width: 576px) {
    height: calc($font-size-l * 1.2);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease-in-out;
    height: calc($font-size-xxxxl * 1.2);
    line-height: 1.2;
    width: auto;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    font-size: $font-size-m;
    user-select: none;

    @media screen and (min-width: 360px) {
      font-size: $font-size-l;
    }

    @media screen and (min-width: 540px) {
      font-size: $font-size-xl;
    }

    @media screen and (min-width: 768px) {
      font-size: $font-size-xxl;
    }

    @media screen and (min-width: 992px) {
      font-size: $font-size-xxxl;
    }

    @media screen and (min-width: 1200px) {
      font-size: $font-size-xxxxl;
    }

    @media screen and (min-width: 1424px) {
      justify-content: flex-end;
    }

    @media (max-width: 1200px) {
      height: calc($font-size-xxxl * 1.2);
    }

    @media (max-width: 992px) {
      height: calc($font-size-xxl * 1.2);
    }

    @media (max-width: 768px) {
      height: calc($font-size-xl * 1.2);
    }

    @media (max-width: 576px) {
      height: calc($font-size-l * 1.2);
    }
  }
}